import { EMPTY_CREDIT_NOTE_HEADER_SEARCH_CRITERIA } from "domain/entity/CreditNote/CreditNoteHeaderSearchCriteria";
import moment from "moment";
import { useCreditNoteHeaderVM } from "presentation/hook/CreditNote/useCreditNoteHeaderVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useCreditNoteHeaderMaintenanceTracked } from "presentation/store/CreditNote/CreditNoteHeaderMaintenanceProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import CreditNoteHeaderTitleBar from "./CreditNoteHeaderTitleBar";
import CreditNoteHeaderSearchPanel from "./Header/LeftPanel/CreditNoteHeaderSearchPanel";
import CreditNoteHeaderTablePanel from "./Header/Table/CreditNoteHeaderTablePanel";

const CreditNoteHeaderMaintenance: React.FC = () => {
    const [ creditNoteHeaderState ] = useCreditNoteHeaderMaintenanceTracked();
    const { isShowCriteriaPanel,isAllowAutoSearch,isBackFromDetail,searchCriteria } = creditNoteHeaderState;
    const creditNoteHeaderVM = useCreditNoteHeaderVM();
    const [isLoading, setIsLoading] = useState(false);
    const [anaInfoState] = useANAInfoTracked();
    useEffect(() => {
        const initialScreen = async() => {   
            try {         
                setIsLoading(true);
                const results = await Promise.allSettled([
                    creditNoteHeaderVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                      } else if (index === 1 && result.status === 'rejected') {

                      }
                })
            } catch(error) {
                setIsLoading(false);
            }
        }
        isAllowAutoSearch && initialScreen().then((data) => {  
            let newSearchCriteria = null;
                if(isBackFromDetail){
                    newSearchCriteria = {...searchCriteria};
                }else{


    
                    newSearchCriteria = {...EMPTY_CREDIT_NOTE_HEADER_SEARCH_CRITERIA} 
                    newSearchCriteria = { ...newSearchCriteria, 
                        createdDateFrom: moment().subtract(10, "days").startOf("day").toDate(),
                        createdDateTo: moment().endOf("day").toDate(), 
                        createdBy: anaInfoState.userName,
                    };
                }
                creditNoteHeaderVM.getCreditNoteHeaders(newSearchCriteria).then((data) => {            
                    setIsLoading(false)
                }).catch(error => {            
                    setIsLoading(false)
                });
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
    }, [anaInfoState.userName, creditNoteHeaderVM, isAllowAutoSearch, isBackFromDetail, searchCriteria])

    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
    return <>

            <div className={`main-comp-wrapper${isShowCriteriaPanel ? '' : ' im-hide-side-form-draggable'}`}>
                <CreditNoteHeaderTitleBar/>

                    <SliderPanel
                        isOpen={true}
                        draggable={false}
                        leftSectionWidth={isShowCriteriaPanel?"25%":"0%"}
                        rightSectionWidth={isShowCriteriaPanel?"75%":"100%"}
                        leftChildren={<CreditNoteHeaderSearchPanel/>}
                        rightChildren={<CreditNoteHeaderTablePanel/>} 
                        />
            </div>
        </>
}
export default memo(CreditNoteHeaderMaintenance);