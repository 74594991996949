import { calculateStateColor, defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { cellRenderWithColorAndCircle } from "presentation/view/components/TableWrapper/TableCellRender";
import { CreditNoteConstant } from "./CreditNoteConstant";
import { DECIMAL_2 } from "presentation/utils/numberUtil";

const CREDIT_NOTE_CONSTANT = CreditNoteConstant.Header;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
let numberFieldList:string[] = [];
export const INITIAL_CREDIT_NOTE_HEADER_COL_DEF: any[] = [
    {
        headerName: CREDIT_NOTE_CONSTANT.HDR_STATTE,
        field: 'hdrState',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 130,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.REQ_NO,
        field: 'reqNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CHARGE_TYPE,
        field: 'chargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.BILL_TO_COMPANY,
        field: 'billToCompany',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CUSTOMER_CODE,
        field: 'customerCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CURRENCY_CODE,
        field: 'currencyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CONSORTIUM_CODE,
        field: 'consortiumCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.VESSEL_CODE,
        field: 'vesselCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.VOYAGE_CODE,
        field: 'voyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.HANDING_TML,
        field: 'handlingTerminal',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.DEPATURE_DATETIME,
        field: 'etd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
        dataType: "dateTime"
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.PO_NO,
        field: 'poNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.YOUR_REF,
        field: 'yourRef',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.OUR_REF,
        field: 'ourRef',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.SL_VESSEL_CODE,
        field: 'slVesselCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.SL_IB_VOYAGE_CODE,
        field: 'slIbVoyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.SL_OB_VOYAGE_CODE,
        field: 'slObVoyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.OPERATING_TML,
        field: 'operatingTml',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.ORIGINAL_INVOICE_NO,
        field: 'origDocNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 190,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CREDIT_NOTE_NO,
        field: 'creditNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 160,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.DRAFT_CREDIT_NOTE_NO,
        field: 'draftCreditNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CREDIT_CHARGE_NO,
        field: 'creditChargeNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 190,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TOTAL_AMOUNT,
        field: 'totalAmount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CREATED_BY,
        field: 'createdBy',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CREATED_DATE,
        field: 'createdDateTime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
        dataType: "dateTime"
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.ISSUED_DATE,
        field: 'docDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
        dataType: "dateTime"
    },

].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};
    cellRenderers['hdrState'] = cellRenderWithColorAndCircle;
    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers, numberFieldList);
    }
);

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};
    externalFnctions['hdrState'] = calculateStateColor;
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions, numberFieldList);
}